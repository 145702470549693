import { RoleLabelsMap } from './types'

export const roleLabelsMap: RoleLabelsMap = {
  admin: {
    label: 'Admin',
    chipColor: 'secondary',
  },
  employee: {
    label: 'Empleado',
    chipColor: 'default',
  },
}
