import React, { useState, useContext } from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Redirect } from 'react-router'

import { useCheckFormErrors, useApiCall } from 'hooks'
import { StringUtils, setAuthHeader } from 'utils'
import { NavBar, snackbar } from 'components'
import { STRINGS, ERRORS } from 'consts'
import { UserContext } from 'contexts'

import { useStyles } from './styles'
import { LoginResponse, LoginPayload } from './types'
import { login } from './api'

const rules = {
  email: [
    {
      validate: (email: string) => StringUtils.isEmail(email),
      message: 'Debe ser un mail válido.',
    },
  ],
  password: [{ validate: (password: string) => password.length >= STRINGS.PASSWORD_MIN_LENGTH }],
}

const Login: React.FC = () => {
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { setAdmin } = useContext(UserContext)

  const handleChangeEmail: React.ChangeEventHandler<HTMLInputElement> = e => {
    setEmail(e.target.value)
  }
  const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPassword(e.target.value)
  }

  const [loginApi, isLoading] = useApiCall<LoginPayload, LoginResponse>(login)

  const data = { email, password }
  const { errors, isAnyFieldEmpty, hasErrors } = useCheckFormErrors(data, rules)
  const isSubmitButtonDisabled = hasErrors || isAnyFieldEmpty || isLoading

  const handleKeyDown: React.KeyboardEventHandler = e => {
    if (hasErrors) return
    const enterKeyCode = 13
    if (e.keyCode === enterKeyCode) {
      onSubmit()
    }
  }

  const onSubmit = async () => {
    if (hasErrors) return
    try {
      const response = await loginApi({
        email,
        password,
      })

      const { token, user } = response
      setAuthHeader(token)
      setAdmin(user)
      localStorage.setItem('token', token)
      localStorage.setItem('admin', JSON.stringify(user))
    } catch (err) {
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  const { admin } = useContext(UserContext)
  if (admin) return <Redirect to="/notifications" />

  return (
    <div className={classes.container}>
      <NavBar />
      <div className={classes.cardContainer}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Ingresar
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              Ingresá con tu usuario
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  fullWidth
                  required
                  value={email}
                  onChange={handleChangeEmail}
                  error={errors.email.hasError}
                  helperText={errors.email.message}
                  onKeyDown={handleKeyDown}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Contraseña"
                  fullWidth
                  type="password"
                  required
                  value={password}
                  onChange={handleChangePassword}
                  error={errors.password.hasError}
                  helperText={errors.password.message}
                  onKeyDown={handleKeyDown}
                />
              </Grid>
            </Grid>
            {isLoading && (
              <Box mt={3}>
                <LinearProgress />
              </Box>
            )}
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disabled={isSubmitButtonDisabled}
              onClick={onSubmit}
            >
              Ingresar
            </Button>
          </CardActions>
        </Card>
      </div>
    </div>
  )
}

export { Login }
