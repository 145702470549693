import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'styles'

const useStyles = makeStyles(() => ({
  areaChartCustomTooltip: {
    backgroundColor: "white",
    border: "1px solid #ccc",
    padding: "10px",
    lineHeight: 1.2,
  },
  chartTitle: {
    textAlign: 'left',
    marginBottom: '10px',
    marginTop: 30,
    display: "block",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  mainTitleContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 10,
  },
  mainTitle: {
    color: 'orange',
    fontSize: "1.25rem",
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  divider: {
    backgroundColor: 'orange',
  },
  chartBody: {
    alignItems: "center",
    background: COLORS.WHITE,
    /* border: 1px solid black; */
    boxShadow: "0 2px 7px 0 rgba(0,0,0,.06)",
  },
  customPieChart: {
    maxWidth: '100%',
    maxHeight: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

export { useStyles }
