import { AxiosRequestConfig } from 'axios'
import { CreateUserPollPayload, UpdateUserPollData, UpdateUserPollPayload, UserPoll, UserPollDetail } from './types'

const getUserPolls = (): AxiosRequestConfig => ({
  url: '/user-poll',
  method: 'GET',
})

const createUserPoll = (userPoll: CreateUserPollPayload): AxiosRequestConfig => {
  const { question, active } = userPoll  
  const payload: CreateUserPollPayload = {
    question: question
  }

  if (active !== null && active !== undefined) payload.active = active.toString();
  
  return {
    url: '/user-poll',
    method: 'POST',
    data: payload,
  }
}

const updateUserPoll = (data: UpdateUserPollData): AxiosRequestConfig => {
  const { question, active } = data.payload
  const payload: UpdateUserPollPayload = {
    question: question
  }

  if (active !== null && active !== undefined) payload.active = active.toString();
  
  return {
    url: `/user-poll/${data.id}`,
    method: 'PUT',
    data: payload,
  }
}

const deleteUserPoll = (id: number): AxiosRequestConfig => ({
  url: `/user-poll/${id}`,
  method: 'DELETE',
})

const getUserPollDetails = (userPoll: UserPoll): AxiosRequestConfig => {
  return {
    url: `/user-poll/${userPoll.id}/detail`,
    method: 'GET'
  }
}

const getUserPollDetailsWithStatistics = (userPoll: UserPoll): AxiosRequestConfig => {
  return {
    url: `/user-poll/${userPoll.id}/detail?statics=true`,
    method: 'GET'
  }
}

const updateUserPollDetail = (data: any): AxiosRequestConfig => {
  const payload = new FormData()
  const { id, userPollId, active, answer, tag, icon } = data
  
  if (active != undefined) payload.append(`active`, active.toString());
  if (answer) payload.append(`answer`, answer);
  if (icon) payload.append(`icon`, icon);
  if (tag) payload.append(`tag`, tag);

  return {
    url: `/user-poll/${userPollId}/detail/${id}`,
    method: 'PUT',
    data: payload,
  }
}

const createUserPollDetail = (data: any): AxiosRequestConfig => {
  const payload = new FormData()
  const { id, userPollId, active, answer, tag, icon } = data
  
  if (active != undefined) payload.append(`active`, active.toString());
  if (answer) payload.append(`answer`, answer);
  if (icon) payload.append(`icon`, icon);
  if (tag) payload.append(`tag`, tag);

  return {
    url: `/user-poll/${userPollId}/detail`,
    method: 'POST',
    data: payload,
  }
}

const deleteUserPollDetail = (userPollDetail: UserPollDetail): AxiosRequestConfig => ({
  url: `/user-poll/${userPollDetail.userPollId}/detail/${userPollDetail.id}`,
  method: 'DELETE',
})

const updateUserPollOrder = (orderArray: number[]): AxiosRequestConfig => ({
  url: '/user-poll/set-order',
  method: 'PUT',
  data: {order: orderArray}
})

export { getUserPolls, createUserPoll, updateUserPoll, deleteUserPoll, getUserPollDetails, updateUserPollDetail, createUserPollDetail, deleteUserPollDetail, updateUserPollOrder, getUserPollDetailsWithStatistics }
