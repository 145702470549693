import React, { useState, useEffect } from 'react'

import { ERRORS } from 'consts'
import { useApiCall } from 'hooks'
import { snackbar } from 'components'

import { getRewards } from './api'
import { DefaultTable } from '../../components'
import { GetRewardsResponse, ParametersDownload, Rewards } from './types'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles'
import { Moment } from 'moment'

const useStyles = makeStyles({

  title: {
    color: '#e78923',
  },
  divider: {
    backgroundColor: '#e78923'
  }

});

const columns = [
  { title: 'ID', field: 'prize_id' },

  { title: 'Título', field: 'prize.title' },

  { title: 'Cantidad', field: 'count_prize' },
]

interface propsUsersStatus {
  startDate: Moment
  endDate: Moment
  storeId: number
}

const RewardsTopFive: React.FC<propsUsersStatus> = ({ startDate, endDate, storeId }) => {

  const [data, setData] = useState<Rewards[] | []>([])
  const [requestUsersTags, isLoading] = useApiCall<ParametersDownload, GetRewardsResponse>(() => getRewards({ startDate, endDate, storeId }))

  useEffect(() => {
    requestUsersTags({ startDate, endDate, storeId }).then(resp => {
      setData(resp.data)
    }).catch(error => {
      setData([])
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    })
  }, [])

  const classes = useStyles()

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" component="h2" className={classes.title}>
          Rewards Utilizados
        </Typography>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12} >
        <DefaultTable
          title="Cupones"
          columns={columns}
          data={data}
          isLoading={isLoading}
          options={{ pageSize: 5, toolbar: false }}
        />
      </Grid>

    </>
  )
}

export { RewardsTopFive }
