import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { Loader, LoaderOptions } from 'google-maps'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/es'

import { tokenLogin } from 'api'
import { snackbar, SnackbarContainer } from 'components'
import { UserContext } from 'contexts'
import { Admin } from 'features/Main/Admins/types'
import { useApiCall } from 'hooks'
import { COLORS } from 'styles'
import { TokenLoginResponse } from 'types'
import { setAuthHeader, removeAuthHeader } from 'utils'

import { Login } from './Login'
import { Main } from './Main'

moment.locale('es')

const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.BASE,
      contrastText: COLORS.WHITE,
    },
    secondary: {
      main: COLORS.ACCENT,
    },
  },
})

const loadGoogleMaps = async () => {
  const options: LoaderOptions = { libraries: ['places'] }
  const loader = new Loader(process.env.REACT_APP_GOOGLE_MAPS_API_KEY, options)
  return loader.load()
}

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [admin, setAdmin] = useState<Admin | undefined>(() => {
    const localAdmin = localStorage.getItem('admin')
    if (!localAdmin) return undefined
    return JSON.parse(localAdmin)
  })

  const [tokenLoginApi] = useApiCall<void, TokenLoginResponse>(tokenLogin)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      setIsLoading(false)
      return
    }

    const updateAdmin = async () => {
      try {
        setAuthHeader(token)
        const { user: updatedAdmin } = await tokenLoginApi()
        setAdmin(updatedAdmin)
        localStorage.setItem('admin', JSON.stringify(updatedAdmin))
      } catch (error) {
        removeAuthHeader()
        setAdmin(undefined)
        localStorage.clear()
      } finally {
        setIsLoading(false)
      }
    }
    updateAdmin()
  }, [tokenLoginApi])

  useEffect(() => {
    loadGoogleMaps()
  }, [])

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <>
          <CssBaseline />
          {!isLoading && (
            <UserContext.Provider value={{ admin, setAdmin }}>
              <Router>
                <Switch>
                  <Route path="/login" component={Login} />
                  <Main />
                </Switch>
              </Router>
              <SnackbarContainer
                ref={ref => {
                  snackbar.containerInstance = ref
                }}
              />
            </UserContext.Provider>
          )}
        </>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
}

export { App }
