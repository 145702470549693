import { AxiosRequestConfig } from 'axios'

import { ParametersDownload } from './types'

const getCoupons = ({
  startDate,
  endDate,
  storeId
}: ParametersDownload): AxiosRequestConfig => {
  const params = {
    ...(startDate ? { startDate: startDate.toISOString() } : {}),
    ...(endDate ? { endDate: endDate.toISOString() } : {}),
    ...(storeId ? { storeId } : {}),
  }

  return {
    url: 'dashboard/coupons',
    method: 'GET',
    params,
  }
}



export { getCoupons }
