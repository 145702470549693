import { AxiosRequestConfig } from 'axios'

import { CreatePrizePayload, UpdatePrizeData } from './types'

const getPrizes = (): AxiosRequestConfig => ({
  url: '/prizes',
  method: 'GET',
})

const createPrize = (prize: CreatePrizePayload): AxiosRequestConfig => {
  const payload = new FormData()
  const { title, shortTitle, icon, level, welcomePrize, birthdayPrize } = prize
  payload.append('title', title)
  payload.append('shortTitle', shortTitle)
  payload.append('icon', icon)
  if (level) payload.append('level', level.toString())
  if (welcomePrize) payload.append('welcomePrize', welcomePrize.toString())
  if (birthdayPrize) payload.append('birthdayPrize', birthdayPrize.toString())

  return {
    url: '/prizes',
    method: 'POST',
    data: payload,
  }
}

const updatePrize = (data: UpdatePrizeData): AxiosRequestConfig => {
  const payload = new FormData()
  const { title, shortTitle, icon, active, level, welcomePrize, additionalText } = data.payload
  if (title) payload.append('title', title)
  if (shortTitle) payload.append('shortTitle', shortTitle)
  if (icon) payload.append('icon', icon)
  if (active !== undefined) payload.append('active', active.toString())
  if (level) payload.append('level', level.toString())
  if (welcomePrize) payload.append('welcomePrize', true.toString())
  if (additionalText) payload.append('additionalText', additionalText)

  return {
    url: `/prizes/${data.id}`,
    method: 'PUT',
    data: payload,
  }
}

const deletePrize = (id: number): AxiosRequestConfig => ({
  url: `/prizes/${id}`,
  method: 'DELETE',
})

export { getPrizes, createPrize, updatePrize, deletePrize }
