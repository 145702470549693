import React from 'react'

import { useApiCall } from 'hooks'
import { Dialog, snackbar } from 'components'
import { ERRORS } from 'consts'

import { Store } from '../types'
import { deleteStore } from '../api'

interface Props {
  handleClose: () => void
  store: Store | null
  onDone: () => void
}

const DeleteStoreDialog: React.FC<Props> = ({ handleClose, store, onDone }) => {
  const [deleteStoreApi, isLoading] = useApiCall<number, void>(deleteStore)

  const onDelete = async () => {
    if (!store) return
    try {
      await deleteStoreApi(store.id)
      onDone()
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  return (
    <Dialog
      title={`¿Está seguro que desea eliminar el local ${store?.name}?`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      onAccept={onDelete}
      okButtonText="Eliminar"
      okButtonProps={{ disabled: isLoading }}
    />
  )
}

export { DeleteStoreDialog }
