import React from "react";
import { Legend, ResponsiveContainer, AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area } from 'recharts';
import { useStyles } from './styles'
import { CHART_COLORS } from "consts";

interface Props {
  data: DataEntry[];
}

interface DataEntry {
  period: string;
  [key: string]: number | string;
}

const AreaChartComponent: React.FC<Props> = ({ data }: Props) => {
  const classes = useStyles()

  const periodTotals: { [key: string]: number } = {};
  data.forEach((entry) => {
    const period = String(entry["period"]);
    periodTotals[period] = 0;

    for (const key in entry) {
      if (key !== "period" && typeof entry[key] === "number") {
        periodTotals[period] += entry[key] as number;
      }
    }
  });

  const percentageData: DataEntry[] = data.map((entry) => {
    const period = entry["period"];
    const newDataEntry: DataEntry = {
      period,
    };

    Object.keys(entry).forEach((key) => {
      if (key !== "period") {
        const value = entry[key] as number;
        const percentage = parseFloat(((value / periodTotals[period]) * 100).toFixed(2));
        newDataEntry[key] = percentage;
      }
    });

    return newDataEntry;
  });

  const getEntryValue = (period: string, name: string) => {
    return data.find((entry) => entry.period === period)![name] || 0;
  }

  const renderTooltipContent = (o: any) => {
    const { payload = [], label } = o;
    if (payload && payload.length) {
      const total = payload.reduce((sum: number, entry: any) => {
        const value = entry.value as number;
        return sum + value;
      }, 0);
 
      return (
        <div className={classes.areaChartCustomTooltip}>
          <h5 style={{ marginTop: 0 }}>Periodo: {label}</h5>
          {payload.slice().reverse().map((entry: any, index: number) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${getEntryValue(label, entry.name)} (${(((typeof entry.value == 'number' ? entry.value : 0) / total) * 100).toFixed(2)}%)`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart data={percentageData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="period" />
        <YAxis
          type="number"
          domain={[0, 100]}
          tickFormatter={(value: number) => (value >= 100) ? `100%` : `${value}%`}
        />
        <Legend 
          verticalAlign="top"
          height={36}
        />
        <Tooltip content={renderTooltipContent} />
        {Object.keys(data[0] || {}).map((key, index) => (
          key !== "period" &&
          <Area
            key={key}
            type='monotone'
            dataKey={key}
            stackId={1}
            stroke={CHART_COLORS[index % CHART_COLORS.length]}
            fill={CHART_COLORS[index % CHART_COLORS.length]}
            fillOpacity={0.4}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default AreaChartComponent;
