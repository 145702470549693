import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'

import { CardDashboad } from '../components'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles'
import { DataCoupons } from '../types';

const useStyles = makeStyles({

    title: {
        color: 'orange',
    },
    divider: {
        backgroundColor: 'orange'
    }

});

interface propsCoupons {
    dataCoupons: DataCoupons,
    dataRewards: DataCoupons
}

const CouponsCard: React.FC<propsCoupons> = ({ dataCoupons, dataRewards }) => {
    const classes = useStyles()

    return (
        <>
            <Grid item xs={6}>
                <Typography variant="h6" component="h2" className={classes.title}>
                    Cupones
                </Typography>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6" component="h2" className={classes.title}>
                    Rewards
                </Typography>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={3}>
                <CardDashboad value={dataCoupons.used} description='Cupones Usados' value_previous={dataCoupons.used_previous} isPercent={false} />
            </Grid>
            <Grid item xs={3}>
                <CardDashboad value={dataCoupons.avg} description='Cupones Promedio' value_previous={dataCoupons.avg_previous} isPercent={true} />
            </Grid>
            <Grid item xs={3}>
                <CardDashboad value={dataRewards.used} description='Rewards Usados' value_previous={dataRewards.used_previous} isPercent={false} />
            </Grid>
            <Grid item xs={3}>
                <CardDashboad value={dataRewards.avg} description='Rewards promedio' value_previous={dataRewards.avg_previous} isPercent={false} />
            </Grid>
        </>
    )
}

export { CouponsCard }

