import React from 'react'
import Grid from '@material-ui/core/Grid'
import { CardDashboad } from '../components'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles'
import { DataVisits } from '../types';

const useStyles = makeStyles({
    title: {
        color: 'orange',
    },
    divider: {
        backgroundColor: 'orange'
    }
});

interface propsVisits {
    data: DataVisits
}

const Visits: React.FC<propsVisits> = ({ data }) => {
    const classes = useStyles()
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6" component="h2" className={classes.title}>
                    Visitas
                </Typography>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={4}>
                <CardDashboad value={data.amount} description='Cantidad de Escaneos' value_previous={data.amount_previous} isPercent={false} />
            </Grid>
            <Grid item xs={4}>
                <CardDashboad value={data.unique} description='Visitantes Unicos' value_previous={data.unique_previous} isPercent={false} />
            </Grid>
            <Grid item xs={4}>
                <CardDashboad value={data.avg} description='Frecuencia Promedio' value_previous={data.avg_previous} isPercent={true} />
            </Grid>
        </>
    )
}

export { Visits }

