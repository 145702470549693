import React from 'react'
import MaterialDrawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import SvgIcon from '@material-ui/core/SvgIcon'
import PersonIcon from '@material-ui/icons/Person'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import LinkIcon from '@material-ui/icons/Link'
import StoreIcon from '@material-ui/icons/Store'
import ReceiptIcon from '@material-ui/icons/Receipt'
import RedeemIcon from '@material-ui/icons/Redeem'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Map from '@material-ui/icons/Map'
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { NavLink } from 'react-router-dom'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { useStyles } from './styles'

interface Path {
  pathname: string
  search?: string
  hash?: string
  state?: object
}

interface Items {
  label: string
  link?: string | Path
  icon?: typeof SvgIcon
}

interface DrawerOption {
  label: string
  link?: string | Path
  icon: typeof SvgIcon
  items?: Items[]
}

const drawerOptions: DrawerOption[] = [
  {
    label: 'Usuarios',
    link: '/users',
    icon: PersonIcon,
  },
  {
    label: 'Links mobile',
    link: {
      pathname: '/drawer-items',
    },
    icon: LinkIcon,
  },
  {
    label: 'Locales',
    link: {
      pathname: '/stores',
    },
    icon: StoreIcon,
  },
  {
    label: 'Cupones',
    link: {
      pathname: '/coupons',
    },
    icon: ReceiptIcon,
  },
  {
    label: 'Rewards automatizados',
    link: {
      pathname: '/rewards-automatizados',
    },
    icon: RedeemIcon,
  },
  {
    label: 'Rewards manuales',
    link: {
      pathname: '/rewards-manuales',
    },
    icon: EmojiEventsIcon,
  },
  // se quita a pedido del cliente (ver PCUP-73)
  /*{
    label: 'Zonas',
    link: {
      pathname: '/zones',
    },
    icon: Map,
  },*/
  {
    label: 'Push notification',
    link: {
      pathname: '/notifications',
    },
    icon: NotificationsIcon,
  },
  {
    label: 'Push notification automatizadas',
    link: {
      pathname: '/notifications/automatic',
    },
    icon: NotificationsIcon,
  },
  {
    label: 'Tags',
    link: {
      pathname: '/tags',
    },
    icon: LoyaltyIcon,
  },
  {
    label: 'Green Test',
    link: {
      pathname: '/user-test',
    },
    icon: AssignmentIcon,
  },
  {
    label: 'Administradores',
    link: {
      pathname: '/admins',
    },
    icon: SupervisorAccountIcon,
  },
  {
    label: 'Descargas',
    link: {
      pathname: '/downloads',
    },
    icon: ArrowDownward,
  },
  {
    label: 'Dashboard',
    link: {
      pathname: '/dashboard',
    },
    icon: DashboardIcon,
  },
]

const Drawer: React.FC = () => {
  const classes = useStyles()
  return (
    <MaterialDrawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <List>
        {drawerOptions.map((option, index) => (
          <NavLink
            exact
            to={option.link || ''}
            activeClassName={classes.activeLink}
            className={classes.link}
            key={option.label + '_' + index}
          >
            <ListItem button key={option.label + '_sub_' + index}>
              <ListItemIcon>
                <option.icon />
              </ListItemIcon>
              <ListItemText primary={option.label} />
              {/* {option.items ? <ExpandMore /> : null} */}
            </ListItem>
          </NavLink>
        ))}
      </List>
    </MaterialDrawer>
  )
}

export { Drawer }
