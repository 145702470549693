import { useApiCall } from "hooks";
import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from "recharts";
import { getUserTest } from "./api";
import { DataUserStatusDashboad, GetDataUserStatusDashboadResponse, ParametersDownload } from "./types";
import { Moment } from 'moment'
import { ERRORS } from "consts";
import { snackbar } from "components";
import { Typography, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import { useStyles } from "./styles";

interface propsUsersStatus {
  startDate: Moment
  endDate: Moment
}

const COLORS = {
  'USER_TEST_COMPLETED': '#08d65f',
  'USER_TEST_PARTIAL': '#8ff755',
  'USER_TEST_NOT_STARTED': '#cf3216'
}

const UserTest: React.FC<propsUsersStatus> = ({ startDate, endDate }) => {
  const classes = useStyles();
  const [data2, setData] = useState<DataUserStatusDashboad[] | []>([])
  const [requestUsersTags, isRequestingUsers] = useApiCall<ParametersDownload, GetDataUserStatusDashboadResponse>(() => getUserTest({ startDate, endDate }))

  useEffect(() => {
    requestUsersTags({ startDate, endDate }).then(resp => {
      setData(resp.data.map((value) => { return { ...value, fill: COLORS[value.code] } }))
    }).catch(error => {
      setData([])
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    })
  }, [startDate, endDate])

  return (
    <Grid container item spacing={2} md={12} style={{ background:"#fff", boxShadow: "0 2px 7px 0 rgba(0,0,0,.06)", paddingTop: 20, paddingBottom: 20 }}>
      <Grid item sm={12} md={8}>
        <ResponsiveContainer width='100%'>
          <BarChart
            width={550}
            height={200}
            data={data2}
            layout="vertical" barCategoryGap={1}
            margin={{
              top: 5,
              right: 20,
              left: 10,
              bottom: 5
            }}
            barSize={40}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis type="number" hide />
            <YAxis type="category" width={150} dataKey="name" />
            <Bar dataKey="value" fill="orange" label={{ position: 'center', fill: 'black' }}></Bar>
          </BarChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item sm={12} md={4}>
        <Typography component="div" color="textSecondary" style={{}}>
          <Box fontWeight='fontWeightMedium' display='inline'>  Nunca lo abrió:</Box>   nunca empezó a responder el Test.
        </Typography>
        <Typography component="div" color="textSecondary" style={{ marginTop: 20 }}>
          <Box fontWeight='fontWeightMedium' display='inline'>  Parcial:</Box>  empezó a responder, pero no lo completó
        </Typography>
        <Typography component="div" color="textSecondary" style={{ marginTop: 20 }}>
          <Box fontWeight='fontWeightMedium' display='inline'>  Completo:</Box>   terminó de responder todas las preguntas
        </Typography>
      </Grid>
    </Grid>
  );
}
export { UserTest }