import React, { useState, useEffect } from "react";
import { Moment } from 'moment'
import { useApiCall } from "hooks";
import { GetDataUserStatusDashboadResponse, DataUserStatusDashboad, ParametersDownload } from "./types";
import { getUserStatus } from "./api";
import { ERRORS } from "consts";
import { snackbar } from "components";
import { CustomPieChart } from "./CustomPieChart";
import Grid from '@material-ui/core/Grid'
import { useStyles } from "./styles";

const USER_STATUS = {
  'NOT_YET': 'NOT_YET',
  'NEW': 'NEW',
  'WE_MISS_YOU': 'WE_MISS_YOU',
  'RECOVERED': 'RECOVERED',
  'RECURRENT': 'RECURRENT',
  'LOST': 'LOST'

}
interface propsUsersStatus {
  startDate: Moment
  endDate: Moment
}

const ActiveVsInactiveUser: React.FC<propsUsersStatus> = ({ startDate, endDate }) => {
  const classes = useStyles();
  const [requestUsersTags, isRequestingUsers] = useApiCall<ParametersDownload, GetDataUserStatusDashboadResponse>(() => getUserStatus({ startDate, endDate }))
  const [activeVsInactive, setActiveVsInactive] = useState<any[]>([])
  const [actives, setActives] = useState<DataUserStatusDashboad[] | []>([])
  const [inactives, setInactives] = useState<DataUserStatusDashboad[] | []>([])

  useEffect(() => {
    requestUsersTags({ startDate, endDate }).then(resp => {
      var actives: Array<DataUserStatusDashboad> = []
      var inactives: Array<DataUserStatusDashboad> = []
      actives.push(resp.data.find(obj => obj['code'] === USER_STATUS.RECURRENT)!)
      actives.push(resp.data.find(obj => obj['code'] === USER_STATUS.NEW)!)
      actives.push(resp.data.find(obj => obj['code'] === USER_STATUS.RECOVERED)!)
      setActives(actives.filter(obj => obj.value > 0))
      inactives.push(resp.data.find(obj => obj['code'] === USER_STATUS.WE_MISS_YOU)!)
      inactives.push(resp.data.find(obj => obj['code'] === USER_STATUS.LOST)!)
      inactives.push(resp.data.find(obj => obj['code'] === USER_STATUS.NOT_YET)!)
      setInactives(inactives.filter(obj => obj.value > 0))
      let countActives = actives.map(obj => obj.value).reduce((accumulator, number) => {
        return accumulator + number;
      });
      let countInactives = inactives.map(obj => obj.value).reduce((accumulator, number) => {
        return accumulator + number;
      });
      setActiveVsInactive([{ name: 'Activos', value: countActives }, { name: 'Inactivos', value: countInactives }].filter(obj => obj.value > 0))
    }).catch(error => {
      setActives([])
      setInactives([])
      setActiveVsInactive([])

      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    })
  }, [startDate, endDate])

  return (
      <Grid container item md={12} style={{ paddingTop: 20, paddingBottom: 20 }} className={classes.chartBody}>
        <CustomPieChart data={activeVsInactive} title='Activos vs Inactivos' />
        <CustomPieChart data={actives} title='Activos' />
        <CustomPieChart data={inactives} title='Inactivos' />
      </Grid>
  );
}
export { ActiveVsInactiveUser }