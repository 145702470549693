import React from 'react'
import Grid from '@material-ui/core/Grid'
import { CardDashboad } from '../components'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles'
import { DataGAReports } from '../types';

const useStyles = makeStyles({
    title: {
        color: 'orange',
    },
    divider: {
        backgroundColor: 'orange'
    }
});

interface propsVisits {
    data: DataGAReports
}

const GAReport: React.FC<propsVisits> = ({ data }) => {
    const classes = useStyles()
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6" component="h2" className={classes.title}>
                    Google Analytics
                </Typography>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={4}>
                <CardDashboad value={data.current.active28DayUsers} description='Usuarios Activos 28 días' value_previous={data.previous.active28DayUsers} isPercent={true} />
            </Grid>
            <Grid item xs={4}>
                <CardDashboad value={data.current.active7DayUsers} description='Usuarios Activos 7 días' value_previous={data.previous.active7DayUsers} isPercent={true} />
            </Grid>
            <Grid item xs={4}>
                <CardDashboad value={data.current.active1DayUsers} description='Usuarios Activos 1 dia' value_previous={data.previous.active1DayUsers} isPercent={true} />
            </Grid>
        </>
    )
}

export { GAReport }

