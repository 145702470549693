import React from 'react';
import { Legend, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { AssetsByPeriodProps } from './types';
import { useStyles } from './styles'
import { CHART_COLORS } from 'consts';

const AverageAssetByPeriod: React.FC<AssetsByPeriodProps> = ({ inputData }: AssetsByPeriodProps) => {
  const classes = useStyles()
  const fillOpacityValue = 0.4;

  const data = inputData.map(entry => {
    const newData: any = { period: entry.period };
    Object.entries(entry).forEach(([key, value]: any) => {
      if (key !== 'period') {
        newData[key] = {
          totalVisits: value.totalVisits,
          totalUsers: value.totalUsers,
          visitsOverUsers: value.totalVisits !== 0 ? (value.totalVisits / value.totalUsers).toFixed(2) : 0,
          tagName: key,
        };
      }
    });
    return newData;
  });
  
  const renderTooltipContent = (o: any) => {
    const { payload = [], label } = o;
    if (payload && payload.length) {
      return (
        <div className={classes.areaChartCustomTooltip}>
          <h5 style={{ marginTop: 0, marginBottom: 0 }}>Periodo: {label}</h5>
          <p style={{ marginTop: 0, fontSize: ".6rem" }}>Visitas/Usuarios</p>
          {payload.map((entry: any, index: number) => {
            const entryName = (typeof entry.name === 'string') ? entry.name.replace('.totalVisits', '') : '';
            const tagData = entry.payload[entryName];
            return (
              <p key={`item-${index}`} style={{ color: entry.color }}>
                {`${entryName}: ${tagData.visitsOverUsers} (${entry.value}/${tagData.totalUsers})`}
              </p>
            );
          })}
        </div>
      );
    }
    return null;
  }

  const tagNames = Object.keys(data[0] || {}).filter(key => key !== 'period');
  const legendData: any[] = tagNames.map((key, index) => ({
    value: key.replace('.totalVisits', ''),
    color: CHART_COLORS[index % CHART_COLORS.length],
    style: { fill: "#2F855A" },
  }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="period" />
        <YAxis />
        {/* <Legend verticalAlign="top" height={36} payload={legendData} /> */}
        <Legend 
          verticalAlign="top"
          height={36}
          payload={legendData}
        />
        <Tooltip content={renderTooltipContent} />
        {tagNames.map((key: string, index) => (
          key !== 'period' && (
            <Bar
              key={key}
              dataKey={key + '.totalVisits'}
              stackId={`stack${index}`}
              fill={CHART_COLORS[index % CHART_COLORS.length]}
              fillOpacity={fillOpacityValue}
            />
          )
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
export default AverageAssetByPeriod;