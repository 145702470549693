import React from "react";
import Grid from '@material-ui/core/Grid'
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip, Text } from "recharts";
import { Typography } from '@material-ui/core';
import { useStyles } from "./styles";

const COLORS = ["#e78923", "#2b5b53", "#bc9878"];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index, value
}: any) => {
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {value} {' (' + `${(percent * 100).toFixed(0)}%` + ')'}
    </text>
  );
};

interface propsUsersStatus {
  data: any[]
  title: string
}

const CustomPieChart: React.FC<propsUsersStatus> = ({ data, title }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h6" component="h6" style={{ textAlign: 'center' }}>
        {title}
      </Typography>
      <PieChart width={400} height={230} margin={{ left: -10 }}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          label={renderCustomizedLabel}
          outerRadius={60}
          labelLine={true}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend />
        <Tooltip />
      </PieChart>
    </Grid>
  );
}
export { CustomPieChart }