import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { FormControl, InputLabel } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import { DatePicker } from '@material-ui/pickers'

import { Dialog, UploadButton, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { STRINGS } from 'consts'

import { ManualReward, CreateManualRewardPayload, CreateManualRewardResponse } from '../../types'
import { Tag } from './../../../Tags/types'
import { createManualReward } from '../../api'
import { useStyles } from './styles'
import { MultiSelect } from "react-multi-select-component";
import { Moment } from 'moment'
import moment from 'moment'
import { resizeFile } from 'utils'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 25,
      message: 'No puede superar los 25 caracteres',
    },
  ],
  shortTitle: [
    {
      validate: (shortTitle: string) => shortTitle.length <= 22,
      message: 'No puede superar los 22 caracteres',
    },
  ],
  additionalText: [
    {
      validate: (value: string) => (value && value.length <= 100) || !value.length,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  tagsSelected: [],
  icon: [],
  expiration: []
}

interface CreateManualRewardDialogProps {
  handleClose: () => void
  onDone: (newManualReward: ManualReward) => void
  tags: Tag[]
  calcUsersByTags: (tagsSelected: any[], allTagsAreRequired?: boolean) => Promise<number>
}

const CreateManualRewardDialog: React.FC<CreateManualRewardDialogProps> = ({ handleClose, onDone, tags, calcUsersByTags }) => {
  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [shortTitle, setShortTitle] = useState('')
  const [icon, setIcon] = useState<File | null>(null)
  const [iconPreview, setIconPreview] = useState('')
  const [image, setImage] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState('')
  const [pushMessage, setPushMessage] = useState(false);
  const [pushTitle, setPushTitle] = useState('');
  const [pushDescription, setPushDescription] = useState('');
  const [active, setActive] = useState(false);
  const [tagsSelected, setTagsSelected] = useState<any[]>([]);
  const [usersByTags, setUsersByTags] = useState(0)
  const [expiration, setExpiration] = useState<Moment | null>(null)
  const [additionalText, setAdditionalText] = useState('')
  const firstExpirationDate = moment().add(1, 'days').format('YYYY-MM-DD hh:mm')
  const [allTagsAreRequired, setAllTagsAreRequired] = useState(false)

  const tagsForSelect = tags.map(el => ({ label: el.title, value: el.id }))

  const [createManualRewardApi, isLoading] = useApiCall<CreateManualRewardPayload, CreateManualRewardResponse>(
    createManualReward,
  )

  useEffect(() => {
    const getUsersByTags = async () => {
      return await calcUsersByTags(tags, false);
    }
    getUsersByTags().then(el => {
      setUsersByTags(el)
    })
  }, []);

  useEffect(() => {
    calcUsersByTags(tagsSelected, allTagsAreRequired).then(el => setUsersByTags(el))
  }, [tagsSelected])

  const handleSetTags = async (e: any[]) => {
    setTagsSelected(e)
  }

  const handleAllTagsAreRequiredChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const total = await calcUsersByTags(tagsSelected, !allTagsAreRequired)
    await setAllTagsAreRequired(!allTagsAreRequired)
    setUsersByTags(total)
  }

  const handleTitleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setTitle(e.target.value)
  }

  const handleShortTitleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setShortTitle(e.target.value)
  }

  const handleIconChange = async (file: File) => {
    setIconPreview(URL.createObjectURL(file))
    const image = await resizeFile(file, 192, 192)
    setIcon(image)
  }

  const handleImageChange = async (file: File) => {
    setImagePreview(URL.createObjectURL(file))
    const image = await resizeFile(file, 600, 600)
    setImage(image)
  }

  const handleChangePushMessage: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPushMessage(!pushMessage);
    if (!pushMessage) {
      setPushTitle('')
      setPushDescription('')
    }
  }

  const handleChangeActive: React.ChangeEventHandler<HTMLInputElement> = e => {
    setActive(!active);
  }

  const handleChangePushTitle: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPushTitle(e.target.value)
  }

  const handleChangepushDescription: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPushDescription(e.target.value)
  }

  const handleDateChange = (newDate: Moment | null) => {
    setExpiration(newDate)
  }

  const handleAdditionalTextChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setAdditionalText(e.target.value)
  }

  const hasPushNotificaitonAndWasntCompleted = pushMessage && (!image || !pushDescription || !pushTitle)

  const fields = { title, shortTitle, icon, tagsSelected, additionalText, expiration }
  const { hasErrors, isAnyFieldEmpty, errors } = useCheckFormErrors(fields, rules)
  const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading || hasPushNotificaitonAndWasntCompleted

  const onCreateManualReward = async () => {
    if (!icon || !expiration) return
    if (pushMessage && (!image || !pushDescription || !pushTitle)) {
      snackbar.show('Todos los campos de la push son requeridos.')
      return
    }
    //!tagsSelected.length
    const manualReward = {
      title,
      shortTitle,
      icon,
      image,
      pushMessage,
      pushTitle,
      pushDescription,
      active,
      tagsSelected,
      expiration: expiration.toISOString(),
      additionalText,
      allTagsAreRequired
    }
    try {
      const { manualReward: newManualReward } = await createManualRewardApi(manualReward)
      onDone(newManualReward)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo crear premio. Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title="Nuevo reward manual"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Crear"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onCreateManualReward}
    >
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <TextField
            label="Título"
            value={title}
            fullWidth
            onChange={handleTitleChange}
            disabled={isLoading}
            required
            error={errors.title.hasError}
            helperText={errors.title.message}
            inputProps={{ maxLength: 25 }}
          />
        </Grid>
        <Grid item xs={11}>
          <TextField
            label="Descripcion"
            value={shortTitle}
            fullWidth
            onChange={handleShortTitleChange}
            disabled={isLoading}
            required
            error={errors.shortTitle.hasError}
            helperText={errors.shortTitle.message}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Texto adicional"
            value={additionalText}
            fullWidth
            onChange={handleAdditionalTextChange}
            disabled={isLoading}
            required
            error={errors.additionalText.hasError}
            helperText={errors.additionalText.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.iconUploadContainer}>
            <UploadButton
              id="manualReward-icon-upload-button"
              accept="image/*"
              label="Subir ícono"
              onChange={handleIconChange}
            />
            <div className={classes.iconUploadPreviewContainer}>
              <img src={iconPreview} alt="" className={classes.iconPreview} />
            </div>
          </div>
        </Grid>
        <Grid item xs={11}>
          <InputLabel className={classes.multiselectTitle}>Tag</InputLabel>
          <FormControl className={classes.fullWidth}>
            <MultiSelect
              options={tagsForSelect}
              value={tagsSelected}
              onChange={handleSetTags}
              ClearIcon
              labelledBy={"Seleccionar Tags"}
              hasSelectAll={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel
            control={<Checkbox onChange={handleAllTagsAreRequiredChange}
              checked={allTagsAreRequired}
            />}
            label="Sólo usuarios con todos los tags seleccionados"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            value={expiration}
            onChange={handleDateChange}
            autoOk
            disablePast
            format={STRINGS.DATE_FORMAT}
            minDate={firstExpirationDate}
            initialFocusedDate={firstExpirationDate}
            disabled={isLoading}
            style={{ width: '100%' }}
            label="Vencimiento"
            required
          />
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel control={<Checkbox onChange={handleChangePushMessage} checked={pushMessage} />} label="Sale con push?" />
        </Grid>
        {pushMessage && (
          <>
            <Grid item xs={11}>
              <TextField
                label="Titulo push"
                value={pushTitle}
                fullWidth
                onChange={handleChangePushTitle}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                label="Descripción push"
                value={pushDescription}
                fullWidth
                onChange={handleChangepushDescription}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className={classes.iconUploadContainer}>
                <UploadButton
                  id="manualReward-image-upload-button"
                  accept="image/*"
                  label="Subir imagen popup"
                  onChange={handleImageChange}
                />
                <div className={classes.iconUploadPreviewContainer}>
                  <img src={imagePreview} alt="" className={classes.iconPreview} />
                </div>
              </div>
            </Grid>
          </>
        )}
        <Grid item xs={11}>
          <FormControlLabel control={<Checkbox onChange={handleChangeActive} checked={active} />} label="Activar Reward" />
        </Grid>
        <Grid item xs={11}><Divider variant="middle" /></Grid>
        <Grid item xs={11}>
          <InputLabel>Esta acción impactará a {usersByTags} usuarios</InputLabel>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { CreateManualRewardDialog }
