import React, { useState, useEffect } from 'react'
import { Typography, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { UserStatus } from './UserStatus'
import { UserFrequency } from './UserFrequency'
import { Moment } from 'moment'
import { UserTest } from './UserTest';
import { ActiveVsInactiveUser } from './ActiveVsInactiveUser';
import AreaChartComponent from './AreaChart';
import { useApiCall } from 'hooks';
import { getAssetsByPeriodByStateVariation, getAssetsByPeriodByFrecuencyVariation, getAssetsByPeriodByFrequencyAverage, getAssetsByPeriodByStatusAverage } from './api';
import { useStyles } from './styles';
import AverageAssetByPeriod from './AverageAssetByPeriod';
import { DataEntry, assetsByPeriodResponse } from './types';
import { CHART_NAMES } from 'consts';

interface propsCharts {
  startDate: Moment
  endDate: Moment
}

type TagGroupBase = Record<string, number>;

type TagGroup = {
  period: string;
} & TagGroupBase;

interface assetsByPeriodVariationResponse {
  data: TagGroup[]
}

const Charts: React.FC<propsCharts> = ({ startDate, endDate }) => {
  const classes = useStyles();
  const [assetsByPeriodStateVariation, setAssetsByPeriodStateVariation] = useState<TagGroup[]|[]>([]);
  const [assetsByPeriodByFrecuencyVariation, setAssetsByPeriodByFrecuencyVariation] = useState<TagGroup[]|[]>([]);
  const [assetsByPeriodByStatusAverage, setAssetsByPeriodByStatusAverage] = useState<DataEntry[]|[]>([]);
  const [assetsByPeriodByFrecuencyAverage, setAssetsByPeriodByFrecuencyAverage] = useState<DataEntry[]|[]>([]);

  const [fetchAssetsByPeriodByState, isloadingAbPSV] = useApiCall<void, assetsByPeriodVariationResponse>(getAssetsByPeriodByStateVariation);
  const [fetchAssetsByPeriodByFrecuency, isloadingAbPFV] = useApiCall<void, assetsByPeriodVariationResponse>(getAssetsByPeriodByFrecuencyVariation);
  const [fetchAssetsByPeriodByStatusAverage, isloadingAbPSA] = useApiCall<void, assetsByPeriodResponse>(getAssetsByPeriodByStatusAverage);
  const [fetchAssetsByPeriodByFrequencyAverage, isloadingAbPFA] = useApiCall<void, assetsByPeriodResponse>(getAssetsByPeriodByFrequencyAverage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const assetsByPeriodStateVariationApiResponse = await fetchAssetsByPeriodByState();
        setAssetsByPeriodStateVariation(assetsByPeriodStateVariationApiResponse.data);

        const assetsByPeriodByFrecuencyVariationApiResponse = await fetchAssetsByPeriodByFrecuency();
        setAssetsByPeriodByFrecuencyVariation(assetsByPeriodByFrecuencyVariationApiResponse.data);

        const assetsByPeriodByStatusAverageApiResponse = await fetchAssetsByPeriodByStatusAverage();
        setAssetsByPeriodByStatusAverage(assetsByPeriodByStatusAverageApiResponse.data);

        const assetsByPeriodByFrequencyAverageApiResponse = await fetchAssetsByPeriodByFrequencyAverage();
        setAssetsByPeriodByFrecuencyAverage(assetsByPeriodByFrequencyAverageApiResponse.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (!isloadingAbPSV && !isloadingAbPFV && !isloadingAbPSA && !isloadingAbPFA) {
      fetchData();
    }
  }, [fetchAssetsByPeriodByState, fetchAssetsByPeriodByFrecuency, fetchAssetsByPeriodByStatusAverage, fetchAssetsByPeriodByFrequencyAverage]);

  const makeTitle = (title: string) => {
    return (
      <Grid item xs={12} className={classes.mainTitleContainer}>
        <Box component="div" className={classes.mainTitle}>
          {title}
        </Box>
        <Divider className={classes.divider} />
      </Grid>
    )
  }

  return (
    <Grid container spacing={2} >
      <Grid container item spacing={2}>
        {makeTitle(CHART_NAMES.BY_STATUS)}
        <UserStatus startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid container item spacing={2}>
        {makeTitle(CHART_NAMES.ACTIVE_USERS)}
        <ActiveVsInactiveUser startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid container item spacing={2}>
        {makeTitle(CHART_NAMES.STATUS_TEST)}
        <UserTest startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid container item spacing={2}>
        {makeTitle(CHART_NAMES.BY_FRECUENCY)}
        <UserFrequency startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid container item spacing={2} md={12}>
        {makeTitle(CHART_NAMES.ASSETS_BY_PERIOD_STATE_VARIATION)}
        {assetsByPeriodStateVariation && (
          <Grid item sm={12} className={classes.chartBody} >
            <AreaChartComponent data={assetsByPeriodStateVariation}/>
          </Grid>
        )}        
      </Grid>
      <Grid container item spacing={2} md={12}>
        {makeTitle(CHART_NAMES.ASSETS_BY_PERIOD_FREQUENCY_VARIATION)}
        {assetsByPeriodByFrecuencyVariation && (
          <Grid item sm={12} className={classes.chartBody} >
            <AreaChartComponent data={assetsByPeriodByFrecuencyVariation}/>
          </Grid>
        )}
      </Grid>
      <Grid container item spacing={2} md={12}>
        {makeTitle(CHART_NAMES.ASSETS_BY_PERIOD_STATUS_AVERAGE)}
        {assetsByPeriodByStatusAverage && (
          <Grid item sm={12} className={classes.chartBody} >
            <AverageAssetByPeriod inputData={assetsByPeriodByStatusAverage}/>
          </Grid>
        )} 
      </Grid>
      <Grid container item spacing={2} md={12}>
        {makeTitle(CHART_NAMES.ASSETS_BY_PERIOD_FREQUENCY_AVERAGE)}
        {assetsByPeriodByFrecuencyAverage && (
          <Grid item sm={12} className={classes.chartBody} >
            <AverageAssetByPeriod inputData={assetsByPeriodByFrecuencyAverage}/>
          </Grid>
        )} 
      </Grid>
    </Grid>
  )
}
export { Charts }