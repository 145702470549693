import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { PieChart, Pie, Cell, Legend } from "recharts";
import { CHART_COLORS } from "consts";

const useStyles = makeStyles(() => ({
  pieChart: {
    margin: 'auto',
    width: 400,
    height: 400
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center', // Centrar horizontalmente
  },
}));

interface ChartProps {
  data: any
}

const PieChartComponent: React.FC<ChartProps> = ({ data }) => {
  const classes = useStyles();
  let totalUsers = 0;
  if (data.length > 0) {
    totalUsers = data.reduce((acc: any, entry: any) => acc + entry.totalUsers, 0);
  }
  
  const renderCustomizedLabel = ({percent, value}: any) => {
    return (value + ' (' + `${(percent * 100).toFixed(2)}%` + ')');
  };

  return (
    <Paper>
      <PieChart width={400} height={400} className={classes.pieChart}>
        <Legend layout="horizontal" verticalAlign="top" align="center" />
        <Pie
          data={data}
          dataKey="totalUsers"
          nameKey={"answer"}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          label={renderCustomizedLabel}
          >
          {data.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </Paper>
  );
}

export { PieChartComponent };
