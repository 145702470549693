import React from 'react'

import { useApiCall } from 'hooks'
import { Dialog } from 'components'

import { DrawerItem } from '../types'
import { deleteDrawerItem } from '../api'

interface Props {
  isOpen: boolean
  handleClose: () => void
  item: DrawerItem | null
  onDone: () => void
}

const DeleteDrawerItemDialog: React.FC<Props> = ({ isOpen, handleClose, item, onDone }) => {
  const [deleteItemApi, isLoading] = useApiCall<number, void>(deleteDrawerItem)

  const onDelete = async () => {
    if (!item) return
    try {
      await deleteItemApi(item.id)
      onDone()
    } catch (err) {
      // TODO: handle properly
      console.error(err)
    }
  }

  return (
    <Dialog
      title={`¿Está seguro que desea eliminar ${item?.name}?`}
      isOpen={isOpen}
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      onAccept={onDelete}
      okButtonText="Eliminar"
      okButtonProps={{ disabled: isLoading }}
    />
  )
}

export { DeleteDrawerItemDialog }
