import React from 'react'

import { Dialog, snackbar } from 'components'
import { useApiCall } from 'hooks'

import { deleteCoupon } from '../api'

interface DeleteCouponDialogProps {
  handleClose: () => void
  onDone: () => void
  couponId: number
  couponTitle: string
}

const DeleteCouponDialog: React.FC<DeleteCouponDialogProps> = ({
  handleClose,
  couponId,
  couponTitle,
  onDone,
}) => {
  const [deleteCouponApi, isLoading] = useApiCall<number, void>(deleteCoupon)

  const onDeleteCoupon = async () => {
    try {
      await deleteCouponApi(couponId)
      onDone()
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo eliminar cupón. Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title={`¿Está seguro que desea eliminar el cupón "${couponTitle}"?`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Eliminar"
      okButtonProps={{ disabled: isLoading }}
      onAccept={onDeleteCoupon}
    />
  )
}

export { DeleteCouponDialog }
